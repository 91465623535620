@import "styles/style.module.scss";

.dynamicMainContent {
  background: $white;
  padding: 32px 0 0;
  min-height: 2000px;
  @include for-size(phone) {
    padding: 24px 0px 0px;
  }
}
